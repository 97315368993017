import { createSlice } from '@reduxjs/toolkit'
import { api } from '../../api'


const productAndPaymentsSlice = createSlice({
    name: 'productAndPaymentsThree',
    initialState: {
        productAndPayments: [],
    },
    reducers: {
        setProductAndPayments: (state, action) => {
            state.productAndPayments = action.payload
        }
    }
})

export const { setProductAndPayments } = productAndPaymentsSlice.actions
export default productAndPaymentsSlice.reducer

export const fetchProductAndPaymentsThree = () => async (dispatch) => {
    try {
        const response = await api.get('/three/product-and-payment/product')
        dispatch(setProductAndPayments(response.data))
    } catch (error) {
        console.log(error);
    }
}
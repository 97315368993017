import { api } from "../api";

export const loginUser = (credentials) => async (dispatch) => {
  dispatch({ type: 'LOGIN_REQUEST' });
  const role = localStorage.getItem('otdel');

  try {
    const response = await api.post('/crm/logins', credentials);
    const { otdel } = response.data.roles;
    console.log(response.data.roles, 'response.data');

    localStorage.setItem('otdel', otdel)
    dispatch({ type: 'LOGIN_SUCCESS', payload: { otdel } });
    if (role === '0') {
      window.location.href = '/home';
    } else if (role === '1') {
      window.location.href = '/otdel_one/dashboard';
    } else if (role === '2') {
      window.location.href = '/otdel_two/dashboard';
    } else if (role === '3') {
      window.location.href = '/otdel_three/dashboard';
    }
  } catch (error) {
    dispatch({
      type: 'LOGIN_FAILURE',
      payload: error.response?.data?.message || 'Произошла ошибка',
    });
  }
};

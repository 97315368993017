import { createSlice } from '@reduxjs/toolkit'
import { api } from '../../api'


const dailyHistorySlice = createSlice({
    name: 'dailyhistory',
    initialState: {
        dailyhistory: [],
    },
    reducers: {
        setdAilyHistory: (state, action) => {
            state.dailyhistory = action.payload
        }
    }
})

export const { setdAilyHistory } = dailyHistorySlice.actions
export default dailyHistorySlice.reducer

export const fetchDailyHistory = () => async (dispatch) => {
    try {
        const response = await api.get('/daily-archive-three/product')
        dispatch(setdAilyHistory(response.data))
    } catch (error) {
        console.log(error);
    }
}
const initialState = {
    otdel: null,
    isLoading: false,
    errorMessage: null,
  };
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'LOGIN_REQUEST':
        return { ...state, isLoading: true, errorMessage: null };
      case 'LOGIN_SUCCESS':
        return { ...state, otdel: action.payload.otdel, isLoading: false };
      case 'LOGIN_FAILURE':
        return { ...state, errorMessage: action.payload, isLoading: false };
      default:
        return state;
    }
  };
  
  export default authReducer;
  
import { createSlice } from '@reduxjs/toolkit'
import { api } from '../../api'


const profitSlice = createSlice({
    name: 'profit-all-data-two',
    initialState: {
        profit: [],
    },
    reducers: {
        setProfit(state, action) {
            state.profit = action.payload
        }
    },
})

export const { setProfit } = profitSlice.actions
export default profitSlice.reducer

export const fetchProfitDatasTwo = () => async (dispatch) => {
    try {
        const response = await api.get('/three/profit-all-datas')
        dispatch(setProfit(response.data))
    } catch (error) {
        console.log(error);
    }
}


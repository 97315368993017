import { configureStore } from '@reduxjs/toolkit'
import auth from '../app/slice/authReducer.js'
import profitOtchet from './slice/profit-otchet.js'
import expences from './slice/expences.js'
import productAndPaymentsSlice from './slice/product-and-payments.js'
import employees from './slice/employees.js'
import customer from './slice/customer.js'
import dailyhistory from './slice/daily-history.js'
import invoiceHistory from './slice/invoice-history.js'
import otdelOneProductAndPaymentsSlice from './slice-otdel-one/product-and-payments.js'
import otdelOneProfitOtchet from './slice-otdel-one/profit-otchet.js'
import otdelOneExpences from './slice-otdel-one/expences.js'
import otdelOneDailyhistory from './slice-otdel-one/daily-history.js'
import otdelOneEmployees from './slice-otdel-one/employees.js'
import otdelOneCustomer from './slice-otdel-one/customer.js'
import otdelOneInvoiceHistory from './slice-otdel-one/invoice-history.js'
import otdelTwoProductAndPaymentsSlice from './slice-otdel-two/product-and-payments.js'
import otdelTwoProfitOtchet from './slice-otdel-two/profit-otchet.js'
import otdelTwoExpences from './slice-otdel-two/expences.js'
import otdelTwoDailyhistory from './slice-otdel-two/daily-history.js'
import otdelTwoEmployees from './slice-otdel-two/employees.js'
import otdelTwoCustomer from './slice-otdel-two/customer.js'
import otdelTwoInvoiceHistory from './slice-otdel-two/invoice-history.js'
import otdelThreeProductAndPaymentsSlice from './slice-otdel-three/product-and-payments.js'
import otdelThreeProfitOtchet from './slice-otdel-three/profit-otchet.js'
import otdelThreeExpences from './slice-otdel-three/expences.js'
import otdelThreeDailyhistory from './slice-otdel-three/daily-history.js'
import otdelThreeEmployees from './slice-otdel-three/employees.js'
import otdelThreeCustomer from './slice-otdel-three/customer.js'
import otdelThreeInvoiceHistory from './slice-otdel-three/invoice-history.js'
import otdelFourProductAndPaymentsSlice from './slice-otdel-four/product-and-payments.js'
import otdelFourProfitOtchet from './slice-otdel-four/profit-otchet.js'
import otdelFourExpences from './slice-otdel-four/expences.js'
import otdelFourDailyhistory from './slice-otdel-four/daily-history.js'
import otdelFourEmployees from './slice-otdel-four/employees.js'
import otdelFourCustomer from './slice-otdel-four/customer.js'
import otdelFourInvoiceHistory from './slice-otdel-four/invoice-history.js'
import otdelFiveProductAndPaymentsSlice from './slice-otdel-five/product-and-payments.js'
import otdelFiveProfitOtchet from './slice-otdel-five/profit-otchet.js'
import otdelFiveExpences from './slice-otdel-five/expences.js'
import otdelFiveDailyhistory from './slice-otdel-five/daily-history.js'
import otdelFiveEmployees from './slice-otdel-five/employees.js'
import otdelFiveCustomer from './slice-otdel-five/customer.js'
import otdelFiveInvoiceHistory from './slice-otdel-five/invoice-history.js'

const combinedReducer = {
    productandpayments: productAndPaymentsSlice,
    auth,
    profitOtchet,
    expences,
    employees,
    customer,
    dailyhistory,
    invoiceHistory,
    otdelOneProductandpayments: otdelOneProductAndPaymentsSlice,
    otdelOneProfitOtchet,
    otdelOneExpences,
    otdelOneDailyhistory,
    otdelOneEmployees,
    otdelOneCustomer,
    otdelOneInvoiceHistory,
    otdelTwoProductandpayments: otdelTwoProductAndPaymentsSlice,
    otdelTwoProfitOtchet,
    otdelTwoExpences,
    otdelTwoDailyhistory,
    otdelTwoEmployees,
    otdelTwoCustomer,
    otdelTwoInvoiceHistory,
    otdelThreeProductandpayments: otdelThreeProductAndPaymentsSlice,
    otdelThreeProfitOtchet,
    otdelThreeExpences,
    otdelThreeDailyhistory,
    otdelThreeEmployees,
    otdelThreeCustomer,
    otdelThreeInvoiceHistory,
    otdelFourProductandpayments: otdelFourProductAndPaymentsSlice,
    otdelFourProfitOtchet,
    otdelFourExpences,
    otdelFourDailyhistory,
    otdelFourEmployees,
    otdelFourCustomer,
    otdelFourInvoiceHistory,
    otdelFiveProductandpayments: otdelFiveProductAndPaymentsSlice,
    otdelFiveProfitOtchet,
    otdelFiveExpences,
    otdelFiveDailyhistory,
    otdelFiveEmployees,
    otdelFiveCustomer,
    otdelFiveInvoiceHistory,
}

export default configureStore({
    reducer: combinedReducer
})